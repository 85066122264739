import { Container } from '../../components/Container'
import { Section } from '../../components/Section'
import { LoginForm } from '../../containers/LoginForm'
import { LoginSSOForm } from '../../containers/LoginSSOForm'

export const LoginSSOPage = () => {
  return (
    <Section variant="login">
      <Container>
        <LoginSSOForm />
      </Container>
    </Section>
  )
}
