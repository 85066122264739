import { lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'

/* Import layouts */
import { AuthLayout } from './layouts/Auth'
import { BaseLayout } from './layouts/Base'

/* Import pages */
// Auth pages
import { AuthPage } from './pages/sign'
import { LoginPage } from './pages/sign/login'
import { NotFoundPage } from './pages/404'

// App selector page
// Profile and user pages
import { ProfilePage } from './pages/profile'
import { UserDetailPage } from './pages/user/detail'
import { UserEditPage } from './pages/user/edit'
import { UserCreatePage } from './pages/user/create'

// Organization pages
import { OrganizationListPage } from './pages/organization/list'
import { OrganizationDetailPage } from './pages/organization/detail'
import { OrganizationEditPage } from './pages/organization/edit'
import { OrganizationCreatePage } from './pages/organization/create'

// Carkulka pages
import { DashboardPage as CarkulkaDashboardPage } from './pages/carkulka/dashboard'
import { Step1 as CarkulkaStep1 } from './pages/carkulka/wizard/1'
import { Step2 as CarkulkaStep2 } from './pages/carkulka/wizard/2'
import { Step3 as CarkulkaStep3 } from './pages/carkulka/wizard/3'
import { Step4 as CarkulkaStep4 } from './pages/carkulka/wizard/4'
import { Step5 as CarkulkaStep5 } from './pages/carkulka/wizard/5'
import { CompletedPage as CarkulkaCompletedPage } from './pages/carkulka/wizard/completed'
import { ConceptsCarPage } from './pages/carkulka/concepts'
import { ContractsCarPage } from './pages/carkulka/contracts'

// Chaloupka pages
import { DashboardPage as ChaloupkaDashboardPage } from './pages/chaloupka/dashboard'

import { Step1 as ChaloupkaStep1 } from './pages/chaloupka/wizard/1'
import { Step2 as ChaloupkaStep2 } from './pages/chaloupka/wizard/2'
import { Step3 as ChaloupkaStep3 } from './pages/chaloupka/wizard/3'
import { Step4 as ChaloupkaStep4 } from './pages/chaloupka/wizard/4'
import { Step5 as ChaloupkaStep5 } from './pages/chaloupka/wizard/5'
import { CompletedPage as ChaloupkaCompletedPage } from './pages/chaloupka/wizard/completed'
import { ContractsPropertyPage } from './pages/chaloupka/contracts'
import { ConceptsPropertyPage } from './pages/chaloupka/concepts'

// Sme pages
import { DashboardPage as SmeDashboardPage } from './pages/sme/dashboard'
import { NewStep1 as NewSmeStep1 } from './pages/sme/wizard/new1'
import { Step2 } from './pages/sme/wizard/2'
import { CompletedPage as SmeCompletedPage } from './pages/sme/wizard/completed'

// News pages
import { NewsListPage } from './pages/news/list'
import { NewsDetailPage } from './pages/news/detail'
import { NewsAdmin } from './pages/news/admin'

// Components
import { Loading } from './components/Loading'
import { FAQPage } from './pages/faq/FAQPage'
import { FAQAdmin } from './pages/faq/FAQAdmin'
import NewsEditItem from './pages/news/NewsEditItem'
import { EditStep1 } from './pages/sme/wizard/edit1'
import { Step3 } from './pages/sme/wizard/3'
import { Step4 } from './pages/sme/wizard/4'
import { Step5 } from './pages/sme/wizard/5'
import { ResetPassword } from './pages/sign/resetPassword'
import { UserListPage } from './pages/user/list'
import { TooFar } from './pages/sme/wizard/TooFar'
import { FlexiGroupPage } from './pages/flexiGroup/list'
import { FlexiGroupDetailPage } from './pages/flexiGroup/detail'
import { FlexiGroupEditPage } from './pages/flexiGroup/edit'
import { FlexiGroupCreatePage } from './pages/flexiGroup/create'
import { LoginSSOPage } from './pages/sign/loginSso'

const NewsNewItem = lazy(() => import('./pages/news/NewsNewItem'))

export const Router = () => {
  return (
    <Routes>
      <Route path="/auth" element={<AuthPage />} />
      <Route path="/auth/login" element={<AuthPage />} />
      <Route path="/sign" element={<AuthLayout />}>
        <Route index element={<AuthPage />} />
        <Route path="/sign/in" element={<LoginSSOPage />} />
        <Route path="/sign/admin" element={<LoginPage />} />
        <Route path="/sign/password" element={<ResetPassword />} />
      </Route>
      <Route path="/carkulka" element={<BaseLayout app="carkulka" />}>
        <Route path="/carkulka/wizard/1" element={<CarkulkaStep1 />} />
        <Route path="/carkulka/wizard/2" element={<CarkulkaStep2 />} />
        <Route path="/carkulka/wizard/3" element={<CarkulkaStep3 />} />
        <Route path="/carkulka/wizard/4" element={<CarkulkaStep4 />} />
        <Route path="/carkulka/wizard/5" element={<CarkulkaStep5 />} />
        <Route path="/carkulka/wizard/completed" element={<CarkulkaCompletedPage />} />
        <Route path="/carkulka/concepts" element={<ConceptsCarPage />} />
        <Route path="/carkulka/contracts" element={<ContractsCarPage />} />
        <Route path="/carkulka" element={<CarkulkaDashboardPage />} />
      </Route>
      <Route path="/chaloupka" element={<BaseLayout app="chaloupka" />}>
        <Route path="/chaloupka/wizard/1" element={<ChaloupkaStep1 />} />
        <Route path="/chaloupka/wizard/2" element={<ChaloupkaStep2 />} />
        <Route path="/chaloupka/wizard/3" element={<ChaloupkaStep3 />} />
        <Route path="/chaloupka/wizard/4" element={<ChaloupkaStep4 />} />
        <Route path="/chaloupka/wizard/5" element={<ChaloupkaStep5 />} />
        <Route path="/chaloupka/wizard/completed" element={<ChaloupkaCompletedPage />} />
        <Route path="/chaloupka/concepts" element={<ConceptsPropertyPage />} />
        <Route path="/chaloupka/contracts" element={<ContractsPropertyPage />} />
        <Route path="/chaloupka" element={<ChaloupkaDashboardPage />} />
      </Route>
      <Route path="/" element={<BaseLayout app="sme" />}>
        <Route path="/" element={<SmeDashboardPage />} />
        <Route path="/sme/wizard/create/1" element={<NewSmeStep1 />} />
        <Route path="/sme/wizard/edit/:id/1" element={<EditStep1 />} />
        <Route path="/sme/wizard/view/:id/1" element={<EditStep1 viewOnly={true} />} />
        <Route path="/sme/wizard/edit/:id/2" element={<Step2 />} />
        <Route path="/sme/wizard/view/:id/2" element={<Step2 viewOnly={true} />} />
        <Route path="/sme/wizard/edit/:id/3" element={<Step3 />} />
        <Route path="/sme/wizard/view/:id/3" element={<Step3 viewOnly={true} />} />
        <Route path="/sme/wizard/edit/:id/4" element={<Step4 />} />
        <Route path="/sme/wizard/view/:id/4" element={<Step4 viewOnly={true} />} />
        <Route path="/sme/wizard/edit/:id/5" element={<Step5 />} />
        <Route path="/sme/wizard/view/:id/5" element={<Step5 viewOnly={true}  />} />
        <Route path="/sme/wizard/:id/completed" element={<SmeCompletedPage />} />
        <Route path="/sme/wizard/too-far/:id" element={<TooFar />} />
        <Route path="/sme/concepts" element={<ConceptsPropertyPage />} />
        <Route path="/sme/contracts" element={<ContractsPropertyPage />} />
      </Route>
      <Route path="/profile" element={<BaseLayout app="sme" />}>
        <Route path="/profile" element={<ProfilePage />} />
      </Route>
      <Route path="/user" element={<BaseLayout app="sme" />}>
        <Route path="/user/list" element={<UserListPage />} />
        <Route path="/user/detail/:id" element={<UserDetailPage />} />
        <Route path="/user/edit/:id" element={<UserEditPage />} />
        <Route path="/user/create/:id" element={<UserCreatePage />} />
      </Route>
      <Route path="/organization" element={<BaseLayout app="sme" />}>
        <Route path="/organization/list" element={<OrganizationListPage />} />
        <Route path="/organization/detail/:id" element={<OrganizationDetailPage />} />
        <Route path="/organization/edit/:id" element={<OrganizationEditPage />} />
        <Route path="/organization/create/:parentId" element={<OrganizationCreatePage />} />
        <Route path="/organization/create/" element={<OrganizationCreatePage />} />
      </Route>
      <Route path="/flexi" element={<BaseLayout app="sme" />}>
        <Route path="/flexi" element={<FlexiGroupPage />} />
        <Route path="/flexi/:id" element={<FlexiGroupDetailPage />} />
        <Route path="/flexi/:id/edit" element={<FlexiGroupEditPage />} />
        <Route path="/flexi/new" element={<FlexiGroupCreatePage />} />
      </Route>
      <Route path="/news" element={<BaseLayout app="sme" />}>
        <Route path="/news/:id" element={<NewsDetailPage />} />
        <Route path="/news" element={<NewsListPage />} />
        <Route path="/news/admin" element={<NewsAdmin />} />
        <Route path="/news/edit/:id" element={<NewsEditItem />} />
        <Route
          path="/news/new"
          element={
            <Suspense fallback={<Loading large />}>
              <NewsNewItem />
            </Suspense>
          }
        />
      </Route>
      <Route path="/faq" element={<BaseLayout app="sme" />}>
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/faq/admin" element={<FAQAdmin />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}
