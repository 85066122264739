import {
  Button,
  Grid,
  Heading,
  Image, Text,
} from '@chakra-ui/react'
import okAppkyLogo from '../assets/images/logo-ok-appky.svg'
import { Card, CardBody } from '../components/Card'
import { useGetAuthOkkSso } from '../model/api/login/useGetAuthOkkSso'
import { useGetAuthOkhSso } from '../model/api/login/useGetAuthOkhSso'
import { Lock } from '../icons'

export const LoginSSOForm = () => {
  const { data: okkSsoData, isLoading: isOkkSsoLoading } = useGetAuthOkkSso()
  const { data: okhSsoData, isLoading: isOkhSsoLoading } = useGetAuthOkhSso()

  const redirectTo = (url?: string) => {
    if (url) {
      window.location.href = url
    }
  }

  return (
    <>
      <Grid sx={{ maxW: 'loginSSOFormMaxW', m: 'auto', gap: 8, alignContent: 'start' }}>
        <Image src={okAppkyLogo} w={'auto'} maxW="14rem" h={12} m="auto" alt="OK appky" />

        <Card>
          <CardBody>
            <Grid sx={{ w: '100%', gap: 'var(--ok-space-4)' }}>
              <Heading as="h2" size="md">
                Centrální přihlášení
              </Heading>

              <Grid sx={{ w: '100%', gap: 'var(--ok-space-2)', gridTemplateColumns: '1fr 1fr' }}>
                <Button
                  disabled={isOkkSsoLoading}
                  variant="solid"
                  colorScheme={'primary'}
                  onClick={() => redirectTo(okhSsoData?.data.url)}
                >
                  <Lock /> SSO OK HOLDING
                </Button>
                <Button
                  disabled={isOkkSsoLoading}
                  variant="outline"
                  onClick={() => redirectTo(okkSsoData?.data.url)}
                >
                  <Lock /> OK KLIENT
                </Button>
              </Grid>

              <Text sx={{ fontSize: 'var(--ok-fontSizes-xs)', color: 'var(--ok-colors-gray-500)'}}>
                Budete přesměrováni na stránku pro přihlášení přes SSO.
              </Text>
            </Grid>
          </CardBody>
        </Card>
      </Grid>
    </>
  )
}
