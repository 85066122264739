import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Heading,
  Image,
  useToast,
} from '@chakra-ui/react'
import { Lock } from '../icons'
import { useNavigate } from 'react-router-dom'

import okAppkyLogo from '../assets/images/logo-ok-appky.svg'
import { Card, CardBody } from '../components/Card'
import { InputGroup } from '../components/InputGroup'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { ILoginData } from '../model/interfaces/entities/ILoginData'
import { usePostLogin } from '../model/api/login/usePostLogin'
import { IUser } from '../model/interfaces/entities/IUser'
import { IS_REQUIRED, MIN_CHARACTERS } from '../model/constants/validationMessages'
import { AutofillSync } from '../components/AutofillSync'

const LoginSchema = Yup.object().shape({
  username: Yup.string().min(3, MIN_CHARACTERS).required(IS_REQUIRED),
  password: Yup.string().min(3, MIN_CHARACTERS).required(IS_REQUIRED),
})

export const LoginForm = () => {
  const navigate = useNavigate()
  const toastMessage = useToast()
  const initialValues: ILoginData = {
    username: '',
    password: '',
  }

  const successCallBack: (data: IUser) => void = (data: any) => {
    navigate('/')
  }
  const errorCallBack: (error: any) => void = (error: any) => {
    toastMessage({
      title: 'Chyba',
      description: error.response.data.error,
      status: 'error',
      isClosable: true,
    })
  }
  const { mutate, isLoading } = usePostLogin(successCallBack, errorCallBack)
  const onSubmitForm: (formValues: ILoginData) => void = (formValues: ILoginData) => {
    mutate(formValues)
  }

  return (
    <>
      <Grid sx={{ maxW: 'loginFormMaxW', m: 'auto', gap: 8, alignContent: 'start' }}>
        <Image src={okAppkyLogo} w={'auto'} maxW="14rem" h={12} m="auto" alt="OK appky" />

        <Card>
          <CardBody>
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => onSubmitForm(values)}
              validationSchema={LoginSchema}
            >
              {({ handleChange, errors, touched, values, setValues }) => (
                <Form>
                  <AutofillSync values={values} setValues={setValues} />
                  <Grid sx={{ gap: 8 }}>
                    <Heading as="h2" size="md">
                      Přihlášení přes uživatelský účet
                    </Heading>

                    <Grid sx={{ gap: 4 }}>
                      <FormControl
                        variant="fullWidth"
                        isInvalid={touched.username && !!errors.username}
                      >
                        <FormLabel htmlFor="username">Uživatelské jméno</FormLabel>
                        <InputGroup
                          id="username"
                          placeholder="Vaše uživatelské jméno"
                          autoComplete="username"
                          sx={{ w: '100%' }}
                          onChange={handleChange}
                          name="username"
                        />
                        {touched.username && <FormErrorMessage>{errors.username}</FormErrorMessage>}
                      </FormControl>

                      <FormControl
                        variant="fullWidth"
                        isInvalid={touched.password && !!errors.password}
                      >
                        <Grid
                          sx={{ gridTemplateColumns: 'auto auto', justifyContent: 'space-between' }}
                        >
                          <FormLabel htmlFor="password">Heslo</FormLabel>
                        </Grid>
                        <InputGroup
                          id="password"
                          type="password"
                          placeholder="Vaše přístupové heslo"
                          autoComplete="password"
                          sx={{ w: '100%' }}
                          selectAllonFocus
                          onChange={handleChange}
                          name="password"
                        />
                        {touched.password && <FormErrorMessage>{errors.password}</FormErrorMessage>}
                      </FormControl>
                    </Grid>

                    <Grid
                      sx={{
                        width: '100%',
                        gridTemplateColumns: 'auto auto',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Button colorScheme="primary" type="submit" isLoading={isLoading}>
                        <Lock />
                        Přihlásit se
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Grid>
    </>
  )
}
